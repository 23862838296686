.spinner {
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  width: 5em;
  height: 5em;
  border-color: #eeca44 transparent #eeca44 transparent;
  border-width: .25em;
  border-style: solid;
  animation: 2s linear spinner infinite;
  position: relative;
  box-sizing: border-box;
}

.spinner::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4em;
  height: 4em;
  margin-left: -2em;
  margin-top: -2em;
  border-radius: 100%;
  border-color: #a35ef9 transparent #a35ef9 transparent;
  border-width: .25em;
  border-style: solid;
  animation: 1s linear reverse-spinner infinite;
  box-sizing: border-box;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@keyframes reverse-spinner {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(-360deg)
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  margin: 0;
  padding: 2em;
  z-index: 10000;
  text-align: center;
}

.overlay::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
