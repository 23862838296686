.mod-set-detail {
  display: block;
  position: relative;
  border: 1px solid dodgerblue;
  background-color: rgba(0, 0, 96, .5);
  padding: 1em;
}

.mod-set-detail .summary {
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0 1em;
}

.mod-set-detail .summary table {
  margin: 0;
  padding: 0;
  text-align: left;
}

.mod-set-detail .summary table th {
  background-color: rgb(31, 115, 140);
  text-align: center;
  font-weight: normal;
}

.mod-set-detail .summary table td {
  padding: .2em .5em;
  font-size: .95em;
}

.mod-set-detail .summary table tr:nth-child(odd) td {
  background-color: rgb(30, 47, 70);
}

.mod-set-detail .summary table tr:nth-child(even) td {
  background-color: rgb(28, 56, 93);
}

.mod-set-detail .summary td.stat-value {
  color: white;
}

.mod-set-detail .summary td.stat-value .mods-value {
  color: #19ccb4;
}

.mod-set-detail .summary table td.stat-type {
  text-align: left;
}

.mod-set-detail .summary table td.stat-value {
  text-align: right;
  width: 9em;
}

.mod-set-detail .summary table td.stat-diff {
  width: 4em;
}

.mod-set-detail .summary table td.optimizer-value {
  width: 3.2em;
}

.mod-set-detail .set-value {
  position: absolute;
  left: 1em;
  bottom: 1em;
  text-align: left;
}
