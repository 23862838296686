.sidebar-wrapper {
  float: left;
  position: relative;
}

.sidebar-wrapper .toggle-sidebar {
  display: block;
  position: absolute;
  top: 4rem;
  left: 17.5rem;
  width: 1em;
  height: 2em;
  margin: 0;
  border-radius: 1em;
  border: 1px solid gray;
  background-color: #333;
  transition: all ease-in-out .5s;
}

.sidebar-wrapper .toggle-sidebar.show {
  left: 0;
  transform: rotate(180deg);
}

.sidebar-wrapper .toggle-sidebar::before {
  content: '';
  position: absolute;
  top: calc(50% - .5em);
  right: .25em;
  width: 0;
  height: 0;
  border-top: .5em solid transparent;
  border-bottom: .5em solid transparent;
  border-right: .5em solid #ccc;
}

.sidebar-wrapper .toggle-sidebar::after {
  content: '';
  position: absolute;
  top: calc(50% - .5em);
  right: 0;
  width: 0;
  height: 0;
  border-top: .5em solid transparent;
  border-bottom: .5em solid transparent;
  border-right: .5em solid #333;
}

.sidebar {
  position: relative;
  left: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  transition: all ease-in-out .5s;
}

.sidebar.hide .toggle-sidebar {
  left: .7em;
}

.sidebar.hide {
  left: -18em;
  margin-right: -20em;
  overflow-x: hidden;
  overflow-y: hidden;
}

.sidebar > div {
  background-color: #444;
  padding: 1em;
  border-radius: 0 .5em .5em 0;
  border: 1px solid #888;
  border-left-width: 0;
  margin: 1em 1em 1em 0;
  width: 18rem;
  box-sizing: border-box;
}

.sidebar-actions {
  text-align: center;
}

.sidebar-actions button {
  margin-bottom: .5em;
}

.sidebar-actions button:last-of-type {
  margin-bottom: 0;
}

.filter-form {
  text-align: center;
}

.filter-form label,
.filter-form .toggle-label,
.sidebar > div h3 {
  display: block;
  border-bottom: 1px solid white;
  margin-top: .5em;
  margin-bottom: .25em;
}

.sidebar-actions h3 {
  margin-top: 0;
}

.sidebar h4 {
  margin: 0;
}

.sidebar div > h3:first-child {
  margin-top: 0;
}
