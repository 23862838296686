.modal .optimizer-messages .missed-goals {
  color: red;
}

.review {
  flex-grow: 1;
  display: flex;
}

.review-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.review-list h2 {
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: .5em;
}

.review-list h3 {
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
}

.review-list .mod-row.individual .character-id h3,
.review-list .mod-row.individual .character-id h4 {
  font-size: .8em;
}

.review-list h4 {
  text-align: center;
  margin-top: 0;
}

.sort-options {
  text-align: center;
  font-size: 24px;
}

.review-list .mods-list {
  overflow-y: auto;
  box-sizing: border-box;
}

/* Add some extra space for hover effects */
.review-list .mods-list::after {
  content: '';
  display: block;
  height: 5em;
}

.mod-row {
  margin: 10px auto;
  text-align: center;
}

.mod-row .mod-detail {
  vertical-align: middle;
  margin-right: 30px;
}

.mod-row .mod-set-block {
  display: inline-block;
  margin: 0 1em;
  vertical-align: middle;
  padding: 1em;
  border: 1px solid dodgerblue;
  background-color: rgba(0, 0, 96, .5);
}

.mod-row .mod-set-detail {
  display: inline-block;
  margin: 0 1em;
  vertical-align: middle;
}

.mod-row div.character-id {
  display: inline-block;
  vertical-align: middle;
}

.mod-row.set div.character-id > .arrow {
  width: 6em;
  height: 6em;
}

.mod-row.individual div.character-id > .arrow {
  width: 4em;
  height: 4em;
}

.mod-row div.character-id > .avatar {
  display: inline-block;
  margin: 1em;
}

.mod-row .actions {
  display: inline-block;
  text-align: center;
  margin: 0;
  vertical-align: middle;
}

.mod-row .actions button {
  display: block;
}

.mod-row.set .avatar {
  font-size: 1.5em;
}

.mod-row.set .mod-image .avatar {
  font-size: 1em;
}

.mod-row.set .mod-stats .avatar {
  font-size: .8em;
}

pre.summary {
  background-color: #222;
  padding: 1em;
  text-shadow: none !important;
  max-height: calc(100vh - 27em);
  overflow-y: auto;
}

.sidebar .filter-form .organize-toggle .toggle-left-value,
.sidebar .filter-form .organize-toggle .toggle-right-value {
  font-size: .8em;
}

.sidebar-hotutils {
  text-align: center;
}

.modal.hotutils-modal {
  max-width: 40em;
}

.modal.hotutils-modal p {
  text-align: left;
}

.modal.hotutils-modal .form-row {
  margin: 1em 0;
}

.modal.hotutils-modal label {
  display: inline-block;
  margin-right: .5em;
  width: 10em;
  text-align: right;
}

.modal.hotutils-modal input {
  font-size: 1.2em;
}

.modal.hotutils-modal p.error {
  visibility: visible;
  text-align: center;
}

.modal.mod-move-progress h3 {
  color: #a35ef9;
}

.modal.cancel-mod-move .canceling {
  height: 1em;
  width: 17em;
  text-align: center;
  margin: .5em auto;
  padding: 0;
}
