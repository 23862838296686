.filter-form.mod-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.mod-filters input[type=number],
.mod-filters input[type=checkbox] {
  display: none;
}

.filter-form.mod-filters label {
  display: inline;
  border: none;
  font-size: 1em;
  margin: auto;
}

.mod-filters span.option {
  display: inline-block;
  border-radius: .25em;
  border: 1px outset dodgerblue;
  background-color: rgb(0, 0, 96);
  margin: .25em;
  padding: .2em;
  opacity: 0.5;
  font-size: 0.9em;
}

.mod-filters .slots,
.mod-filters .sets {
  line-height: 0;
}

.mod-filters span.option.pips-button {
  display: block;
  width: 4em;
  margin: .25em auto;
  padding: .2em .4em;
}

.mod-filters span.option.tier {
  display: block;
  width: 4em;
  margin: .25em auto;
}

.mod-filters .option-image {
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
  padding: .2em;
  opacity: 0.5;
  background-origin: content-box;
  background-clip: content-box;
  line-height: 0;
}

.mod-filters .option-image.shape {
  background-image: url('/img/empty-mod-shapes.png');
  background-size: 15em 5em;
}

.mod-filters .option-image.arrow {
  background-position-x: -2.5em;
}

.mod-filters .option-image.diamond {
  background-position-x: -5em;
}

.mod-filters .option-image.triangle {
  background-position-x: -7.5em;
}

.mod-filters .option-image.circle {
  background-position-x: -10em;
}

.mod-filters .option-image.cross {
  background-position-x: -12.5em;
}

.mod-filters .option-image.set {
  background-image: url('/img/icon-buffs.png');
  background-size: 20em 5em;
}

.mod-filters .option-image.offense {
  background-position-x: -2.5em;
}

.mod-filters .option-image.defense {
  background-position-x: -5em;
}

.mod-filters .option-image.speed {
  background-position-x: -7.5em;
}

.mod-filters .option-image.critchance {
  background-position-x: -10em;
}

.mod-filters .option-image.critdamage {
  background-position-x: -12.5em;
}

.mod-filters .option-image.potency {
  background-position-x: -15em;
}

.mod-filters .option-image.tenacity {
  background-position-x: -17.5em;
}

.mod-filters input.select + .option-image {
  opacity: 1;
}

.mod-filters input.unselect + .option-image {
  opacity: 1;
  background-position-y: -2.5em;
}

.mod-filters input.select + span.option {
  border-style: inset;
  background-color: rgb(0, 0, 64);
  opacity: 1;
}

.mod-filters input.unselect + span.option {
  border-style: inset;
  background-color: rgb(64, 0, 0);
  border-color: rgb(128, 0, 0);
  opacity: 1;
}

.mod-filters .actions {
  text-align: center;
}

.mod-filters button {
  font-size: .9em;
}

.mod-filters .slots label:nth-child(even)::after {
  content: '\a';
  white-space: pre;
}

.mod-filters .form-actions {
  text-align: center;
  margin-bottom: 1em;
}

.mod-filters .form-actions:last-child {
  margin-top: 1em;
  margin-bottom: 0;
}

.mod-filters .form-actions button {
  font-size: 1em;
  margin: .2em;
}

#mod-filters > div {
  margin-bottom: .6em;
}

#mod-filters > div:last-child {
  margin-bottom: 0;
}

#slot-filters,
#set-filters,
#pips-filters,
#tier-filters,
#level-filters,
#equipped-filters {
  width: 7em;
  vertical-align: top;
  display: inline-block;
}

#level-filters {
  width: 9em;
  margin-left: 0
}

#equipped-filters {
  width: 6em;
  margin-right: 0
}
