.mod-image {
  display: inline-block;
  text-align: right;
  position: relative;
  z-index: 100;
}

.mod-image.square {
  left: 20px;
}

.mod-image.circle {
  left: 17px;
}

.mod-image.cross {
  left: 3px;
}

.mod-image .avatar {
  position: absolute;
  left: -.5em;
  bottom: -.5em;
  width: 2em;
  height: 2em;
}

.mod-image.cross .avatar {
  left: 2em;
}

.mod-slot-image {
  position: relative;
  background-image: url('/img/mod-shape-atlas.png');
  background-size: 48em 20em;
  width: 3.99em;
  height: 3.99em;
}

.mod-image.arrow .mod-slot-image {
  font-size: 1.05em;
}

.mod-image.diamond .mod-slot-image {
  font-size: 1.05em;
}

.mod-image.triangle .mod-slot-image {
  font-size: 1.04em;
}

.mod-image.circle .mod-slot-image {
  font-size: 1.05em;
}

.mod-image.cross .mod-slot-image {
  font-size: 1.1em;
}

.mod-image.square .mod-slot-image {}
.mod-image.arrow .mod-slot-image {
  background-position-x: -4em;
}
.mod-image.diamond .mod-slot-image {
  background-position-x: -8em;
}
.mod-image.triangle .mod-slot-image {
  background-position-x: -12em;
}
.mod-image.circle .mod-slot-image {
  background-position-x: -16em;
}
.mod-image.cross .mod-slot-image {
  background-position-x: -20em;
}
.mod-image.square.dots-6 .mod-slot-image {
  background-position-x: -24em;
}
.mod-image.arrow.dots-6 .mod-slot-image {
  background-position-x: -28em;
}
.mod-image.diamond.dots-6 .mod-slot-image {
  background-position-x: -32em;
}
.mod-image.triangle.dots-6 .mod-slot-image {
  background-position-x: -36em;
}
.mod-image.circle.dots-6 .mod-slot-image {
  background-position-x: -40em;
}
.mod-image.cross.dots-6 .mod-slot-image {
  background-position-x: -44em;
}

.mod-image.gray .mod-slot-image {
  background-position-y: -0.2em;
}
.mod-image.green .mod-slot-image {
  background-position-y: -4.2em;
}
.mod-image.blue .mod-slot-image {
  background-position-y: -8.2em;
}
.mod-image.purple .mod-slot-image {
  background-position-y: -12.2em;
}
.mod-image.gold .mod-slot-image {
  background-position-y: -16.2em;
}

.mod-slot-image::after {
  content: '';
  display: block;
  position: absolute;
  background-image: url('/img/mod-icon-atlas.png');
  background-size: 8em 5em;
  width: 1em;
  height: 1em;
}

.mod-image.square .mod-slot-image::after {
  font-size: 1.3em;
  left: 1.3em;
  top: .68em;
}
.mod-image.arrow .mod-slot-image::after {
  font-size: 1.1em;
  left: 1.8em;
  top: .7em;
}
.mod-image.diamond .mod-slot-image::after {
  font-size: 1.2em;
  left: 1.16em;
  top: 1.01em;
}
.mod-image.triangle .mod-slot-image::after {
  font-size: 1.05em;
  left: 1.4em;
  top: 1.45em;
}
.mod-image.circle .mod-slot-image::after {
  font-size: 1.2em;
  left: 1.17em;
  top: 1.01em;
}
.mod-image.cross .mod-slot-image::after {
  font-size: 1.2em;
  left: 1.18em;
  top: 1.03em;
}

.mod-image.offense .mod-slot-image::after {
  background-position-x: -1em;
}
.mod-image.defense .mod-slot-image::after {
  background-position-x: -2em;
}
.mod-image.speed .mod-slot-image::after {
  background-position-x: -3em;
}
.mod-image.critchance .mod-slot-image::after {
  background-position-x: -4em;
}
.mod-image.critdamage .mod-slot-image::after {
  background-position-x: -5em;
}
.mod-image.potency .mod-slot-image::after {
  background-position-x: -6em;
}
.mod-image.tenacity .mod-slot-image::after {
  background-position-x: -7em;
}

.mod-image.green .mod-slot-image::after {
  background-position-y: -1em;
}
.mod-image.blue .mod-slot-image::after {
  background-position-y: -2em;
}
.mod-image.purple .mod-slot-image::after {
  background-position-y: -3em;
}
.mod-image.gold .mod-slot-image::after {
  background-position-y: -4em;
}

.mod-level {
  display: inline-block;
  padding: 1px;
  font-weight: 100;
  position: absolute;
  background-size: 100%;
  z-index: -1;
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
}

.mod-level.square {
  background-image: url('/img/modlevel-square.png');
  width: 2.08em;
  height: 1.5em;
  left: -1.45em;
  top: 1.85em;
  padding: .15em .6em .15em .15em;
}

.mod-level.arrow {
  background-image: url('/img/modlevel-arrow.png');
  width: 1.56em;
  height: 1.71em;
  bottom: -.85em;
  right: 1.4em;
  padding: .43em .14em .14em;
}

.mod-level.diamond {
  background-image: url('/img/modlevel-diamond.png');
  width: 1.71em;
  height: 2.64em;
  bottom: -.86em;
  right: .19em;
  padding: 1.29em .14em .14em;
}

.mod-level.triangle {
  background-image: url('/img/modlevel-triangle.png');
  width: 2.29em;
  height: 1.57em;
  bottom: 1.06em;
  right: -1.67em;
  padding: .14em .14em .14em .64em;
}

.mod-level.circle {
  background-image: url('/img/modlevel-circle.png');
  width: 2.5em;
  height: 1.57em;
  left: -1.24em;
  top: 1.95em;
  padding: .14em 1.07em .14em .14em;
}

.mod-level.cross {
  background-image: url('/img/modlevel-cross.png');
  width: 1.79em;
  height: 2.21em;
  left: -.2em;
  bottom: -.67em;
  padding: .86em .21em .14em .14em;
}
