.gold {
  color: #eeca44 !important;
}

.purple {
  color: #a35ef9 !important;
}

.blue {
  color: #2997f1 !important;
}

.green {
  color: #8fff3a !important;
}

.gray {
  color: #ccfffe !important;
}

.red-text {
  color: red !important;
}

.increase {
  color: #60bd1b;
}

.increase::before {
  content: '+';
}

.decrease {
  color: #e64343;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.box {
  display: inline-block !important;
  border: 1px solid dodgerblue !important;
  padding: .25em !important;
}

.inset {
  z-index: 100;
  position: relative;
}

.inset::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  border-radius: 2px;
  z-index: -1;
}

.inset::before {
  position: absolute;
  content: '';
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background-image: linear-gradient(30deg, #afa992 10%, /*#796f3e 30%, #2f2a13 50%, */ black 70%);
  border-radius: 2px;
  z-index: -2;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  text-align: left;
  height: 100%;
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: .4em;
  height: .4em;
  margin-left: 1em;
}

::-webkit-scrollbar-track {
  border: 1px inset dodgerblue;
  background: #1e2f46;
}

::-webkit-scrollbar-thumb {
  background: dodgerblue;
  min-height: 3em;
}

.App-header {
  flex: 0 1 auto;
  display: grid;
  grid-template-areas: auto 1fr / auto auto;
  background-color: #222;
  padding: 0;
  color: white;
  border-bottom: 1px solid gray;
}

.App-title {
  grid-row: 1;
  grid-column: 1;
  font-size: 1.5em;
  margin: .5em;
  background-image: url('/img/gold-crit-dmg-arrow-mod-cropped.png');
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 2em 2em;
  padding-left: 2.5em;
}

.App-title .subtitle {
  display: block;
  font-size: .5em;
}

.App-header nav {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  align-items: flex-end;
  position: relative;
  bottom: -1px;
}

.App-header nav button {
  margin-bottom: 0;
  border-radius: 5px 5px 0 0;
  border: 1px solid gray;
  background-color: #202020;
  color: lightgray;
}

.App-header nav button:hover, .App-header nav button:focus {
  color: white;
}

.App-header .actions {
  grid-row: 1 / span 2;
  grid-column: 2;
  text-align: right;
}

.App-header .actions label:not(.button),
.App-header .actions input:not(.button){
  margin: 0 .25em;
  vertical-align: middle;
}

.App-header input#ally-code {
  font-size: 1.2em;
}

.App-header .actions .fetch-actions {
  display: inline-block;
}

.App-header .actions form {
  display: inline-block;
}

.App-footer {
  flex: 0 1 auto;
  background-color: #222;
  padding: 0;
  color: lightgray;
  font-size: .75em;
  border-top: 1px solid gray;
  text-align: center;
  position: relative;
}

.App-footer a {
  color: lightgray;
  text-decoration: underline;
}

.App-footer #donate-button {
  display: inline-block;
  vertical-align: middle;
}

.App-footer .version {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: .75em;
  margin: .5em;
}

.app-body {
  flex: 1 1 auto;
  display: flex;
  background: black url('/img/cantina-background.jpg') no-repeat fixed;
  background-size: cover;
  overflow: hidden;
  color: white;
  text-shadow: 2px 2px 1px black;
  align-items: stretch;
}

.app-body a {
  color: white;
  text-decoration: underline;
}

.app-body a.call-out {
  font-weight: bold;
  color: #eeca44;
}

nav.sub-nav {
  border-bottom: 1px solid dodgerblue;
  position: relative;
}

nav.sub-nav button {
  border-radius: 5px 5px 0 0;
  border: 1px solid dodgerblue;
  background-color: #202020;
  margin-bottom: -1px;
  color: lightgray;
}

button, .button {
  display: inline-block;
  margin: .25em;
  background-color: #46801a;
  border: 1px outset limegreen;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 300;
  color: white;
  padding: 1px .25em;
  opacity: .9;
  cursor: pointer;
  line-height: 1.3;
  vertical-align: middle;
  text-decoration: none;
  text-shadow: none;
}

button[disabled],
button[disabled]:hover {
  opacity: .5;
  cursor: auto;
}

button.small, .button.small {
  font-size: .6em;
}

button:active, .button:active {
  border: 1px inset limegreen;
}

button.red, .button.red {
  background-color: darkred;
  border: 1px outset red;
}

button.red:active, .button.red:active {
  border: 1px inset red;
}

button.blue, .button.blue {
  background-color: #0b4884;
  border: 1px outset dodgerblue;
  color: white !important;
}

button.blue:active, .button.blue:active {
  border: 1px inset dodgerblue;
}

button:hover, button:focus,
.button:hover, .button:focus {
  outline: 0;
  opacity: 1;
}

button.link {
  text-decoration: underline;
  background-color: transparent;
  border: none;
  color: #d3d3d3;
  font-size: 1em;
  margin: .1em;
  padding: 0;
  font-weight: 400;
}

nav button.active {
  border-bottom: none;
  color: white;
  position: relative;
}

.icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin: 0 .25em;
  vertical-align: middle;
}

.welcome {
  text-align: center;
  margin: 0 auto;
  max-width: 45em;
}

.modal.reset-modal {
  width: 40em;
}

.modal.error-modal {
  border: 1px solid darkred;
  background-color: #ead118;
  width: 30em;
  min-height: 12em;
  color: black;
  text-shadow: none;
}

.modal.error-modal .warning-label {
  float: right;
  height: 10em;
}

.modal.error-modal p {
  word-break: break-word;
}

.modal.changelog-modal {
  max-width: 45em;
}

.modal.pull-unequipped-modal {
  max-width: 40em;
}

.modal .help {
  max-width: 30em;
}

.modal .fetch-results {
  max-width: 40em;
}

.modal .progress {
  height: 1em;
  width: 17em;
  margin: .5em auto;
  border-radius: .5em;
  border: 1px solid limegreen;
  padding: 0;
  overflow: hidden;
}

.modal .progress .progress-bar {
  display: block;
  height: 100%;
  background-image: repeating-linear-gradient(-45deg, #8fff3a, #46801a 1em, #8fff3a 2em);
  background-size: 2.828em 1em;
  background-repeat: repeat-x;
  animation: Progress 1s linear infinite;
  transition: width .5s;
}


strong {
  font-weight: bold;
  color: #a35ef9;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 1em;
  color: white;
  background-color: rgb(0, 0, 64);
  border: 1px solid dodgerblue;
  border-radius: 0;
  padding: .25em;
}

[draggable='true'] {
  cursor: grab;
}

.add-ally-code-form,
.add-ally-code-form input[type='text'] {
  font-size: 1.2em;
}

.add-ally-code-form h4 {
  color: #eeca44;
  margin-top: 0;
  margin-bottom: 1em;
}

.add-ally-code-form .actions {
  margin-top: 1em;
}

input {
  color: #222222;
}

li {
  text-align: left;
  margin-bottom: .5em;
}

a {
  cursor: pointer;
}

img.fit {
  width: 100%;
}

.form-item {
  display: inline-block;
}
