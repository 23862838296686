.mod-set-view {
  width: 12em;
  height: calc(12em * 701 / 400);
  display: inline-block;
  background-image: url('/img/modset-background.png');
  background-size: 100%;
  position: relative;
}

.mod-set-view .mod {
  position: absolute;
}

.mod-set-view .mod .no-move .mod-slot-image {
  background-image: url('/img/mod-shape-atlas-faded.png');
}

.mod-set-view .mod .no-move .mod-slot-image::after {
  background-image: url('/img/mod-icon-atlas-faded.png');
}

.mod-set-view .mod .no-move .avatar::after {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 50%);
}

.mod-set-view .mod .icon {
  display: block;
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  background-image: url('/img/character_icons.png');
  background-size: 15em 3em;
  font-size: .9em;
  z-index: 101;
}

.mod-set-view .mod .icon.slice {
  background-position-x: -1.5em;
}

.mod-set-view .mod .mod-level {
  background: none;
}

.mod-set-view .mod .pips::before {
  background: none;
}

.mod-set-view .mod .pips::after {
  background: none;
}

.mod-set-view .mod .mod-stats {
  display: none;
}

.mod-set-view .mod:hover .mod-stats {
  display: block;
  position: absolute;
  border: 1px solid dodgerblue;
  background-color: rgb(0, 0, 66);
  padding: 1em;
  z-index: 1000;
}

.mod-set-view .mod.square {
  left: .25em;
  top: .7em;
}

.mod-set-view .mod.square .pips {
  left: 0;
  top: 4px;
}

.mod-set-view .mod.square .mod-level {
  top: 1.83em;
}

.mod-set-view .mod.square .icon.level {
  right: -1.7em;
  top: 1.6em;
}

.mod-set-view .mod.square .icon.slice {
  right: -1.7em;
  top: 3.3em;
}

.mod-set-view .mod.arrow {
  right: 1.09em;
  top: -.08em;
}

.mod-set-view .mod.arrow .pips {
  left: 3px;
  top: 3px;
}

.mod-set-view .mod.arrow .mod-level {
  bottom: -.95em;
  right: 1.7em;
}

.mod-set-view .mod.arrow .icon.level {
  right: -.5em;
  top: 1.6em;
}

.mod-set-view .mod.arrow .icon.slice {
  right: -.5em;
  top: 3.3em;
}

.mod-set-view .mod.diamond {
  left: 2.16em;
  top: 9.1em;
}

.mod-set-view .mod.diamond .pips {
  left: 4px;
  top: 2px;
}

.mod-set-view .mod.diamond .mod-level {
  bottom: -.9em;
  right: .4em;
}

.mod-set-view .mod.diamond .icon.level {
  left: .3em;
  top: 1.6em;
}

.mod-set-view .mod.diamond .icon.slice {
  top: 1.6em;
  right: .3em;
}

.mod-set-view .mod.triangle {
  right: 1.7em;
  top: 7.17em;
}

.mod-set-view .mod.triangle .pips {
  left: 1px;
  top: 5px;
}

.mod-set-view .mod.triangle .mod-level {
  right: -1.25em;
  bottom: 1em;
}

.mod-set-view .mod.triangle .icon.level {
  left: .3em;
  top: 1.6em;
}

.mod-set-view .mod.triangle .icon.slice {
  top: 1.6em;
  right: .3em;
}

.mod-set-view .mod.circle {
  left: 1.05em;
  bottom: .2em;
}

.mod-set-view .mod.circle .pips {
  left: 5px;
  top: 3px;
}

.mod-set-view .mod.circle .mod-level {
  left: -1.25em;
  top: 1.9em;
}

.mod-set-view .mod.circle .icon.level {
  right: -1.1em;
  top: 1.6em;
}

.mod-set-view .mod.circle .icon.slice {
  right: -1.1em;
  top: 3.3em;
}

.mod-set-view .mod.cross {
  right: 1.12em;
  bottom: 1.9em;
}

.mod-set-view .mod.cross .pips {
  left: 6px;
  top: 4px;
}

.mod-set-view .mod.cross .mod-level {
  left: -.35em;
  bottom: -.5em;
}

.mod-set-view .mod.cross .icon.level {
  left: .5em;
  top: 1.5em;
}

.mod-set-view .mod.cross .icon.slice {
  right: 0;
  top: 1.5em;
}
