div.toggle-wrapper {
  margin-bottom: .5em;
}

input.toggle {
  display: none;
}

div.toggle-label {
  border-bottom: 1px solid white;
  margin-bottom: .3em;
}

div.toggle-wrapper label {
  font-size: .9em;
  border: none;
  margin: 0;
}

div.toggle-wrapper.disabled label {
  opacity: .6;
}

.toggle-left-value,
.toggle-right-value {
  cursor: pointer;
}

span.toggle-switch {
  display: inline-block;
  width: 2em;
  height: 1em;
  position: relative;
  border-radius: .25em;
  background-color: #222;
  vertical-align: middle;
  margin: 0 .5em;
  cursor: pointer;
}

span.toggle-switch::after {
  content: '';
  display: block;
  position: absolute;
  border-radius: .25em;
  border: .2em outset #72e817;
  width: 1.2em;
  height: 1.2em;
  box-sizing: border-box;
  top: -.1em;
  left: .9em;
  right: .9em;
  background-color: #61c714;
  transition-property: left;
  transition-duration: .2s;
}

span.toggle-switch.left::after {
  left: 0;
}

span.toggle-switch.right::after {
  right: 0;
}
