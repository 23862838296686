.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  margin: 0;
  padding: 2em;
  z-index: 10000;
  text-align: center;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.overlay::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal {
  border: 1px solid dodgerblue;
  background-color: rgb(0, 0, 64);
  margin: 0 auto;
  display: inline-block;
  vertical-align: middle;
  padding: 1em;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.modal.flash h2 {
  color: #eeca44;
}

.modal.flash .errors {
  color: orangered;
  border-bottom: 1px solid white;
}

.modal.notice {
  max-width: 40em;
}

.modal.notice h2 {
  color: #eeca44;
}

.modal.notice h3 {
  color: #e64343;
}
